<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>OMS</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管家</el-breadcrumb-item>
           <el-breadcrumb-item>工单管理</el-breadcrumb-item>
           <el-breadcrumb-item>工单列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 查询框   -->
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>生成时间：</label>
                 <date-packer class="width-220" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
              </el-row>
              <el-row class="search-item">
                 <label>工单状态：</label>
                 <el-select  class="width-220" v-model="status" :placeholder="$t('msg.Pleaseselectstatus')">
                    <el-option v-for="item in states" :key="item.value" :label="item.label" :value="item.value"></el-option>
                 </el-select>
              </el-row>
              <el-row class="search-item">
                 <label>工单分类：</label>
                 <el-select  class="width-220" v-model="gdtype" :placeholder="$t('msg.Pleaseselectstatus')" >
                    <el-option v-for="item in typeCodeList" :key="item.value" :label="item.lable" :value="item.value"></el-option>
                 </el-select>
              </el-row>
              <el-button  class="m-bottom-5 bg-gradient" type="primary"   @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
              <el-button  class="m-bottom-5" type="primary" @click="handleQuery(false)" plain><span v-text="$t('msg.reset')">重置</span></el-button>
           </el-row>
        </el-row>
        <!-- 主体内容 -->
        <el-row class="table-box">
           <!-- 表格按钮 -->
           <div class="table-head-btn">
              <el-row></el-row>
              <el-row class="right-btn">
                 <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus"  style="float: right" @click="handleAdd('add')"><span>创建工单</span></el-button>
              </el-row>
           </div>
           <!-- 表格 -->
           <el-table
                ref="mainTable"
                :data="tableData"
                border fit
                :stripe="true"
                >
        <el-table-column label="序号" width="60">
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" min-width="140">
          <template slot-scope="scope">
            {{scope.row.createTime}}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="工单标题" width="300"></el-table-column>
        <el-table-column label="工单类型" min-width="150">
          <template slot-scope="scope">
            {{scope.row.typeName}}
          </template>
        </el-table-column>
        <el-table-column prop="receiveName" label="完成人" min-width="150"></el-table-column>
        <el-table-column prop="updateTime" label="完成时间" min-width="150"></el-table-column>
        <el-table-column label="工单状态" min-width="140">
          <template slot-scope="scope">
            {{scope.row.status | stutas}}
          </template>
        </el-table-column>
        <el-table-column :label="$t('msg.operating')" min-width="240" sortable fixed="right">
          <template slot-scope="scope">
            <el-button  type="text" icon="el-icon-warning-outline" @click="handleDetail('detail', scope.row)" >
              <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
            </el-button>
            <el-button type="text" icon="el-icon-delete" style="color: #F56C6C;" @click="handleDel(scope.row.id)" >
              <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
           <!-- 分页 -->
           <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
        </el-row>
     </el-row>
  </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import {mapState} from 'vuex'
   import {hotelMan} from "@/api/interface/oms";
   export default {
      data(){
         return{
            tableData: [],       // 表格数据
            loading: true,       // 加载效果
            total: 0,            // 数据总数
            isAcceptance: false, // 是否显示验收弹窗
            /* 查询参数 */
            limit: 0,            // 每页数
            page: 1,             // 当前页
            status: '',          //工单状态
            states: [            //所有状态
               {label:'待接单',value:'NO_RECEIVED'},
               {label:'已接单',value:'ORDER_RECEIVED'},
               {label:'已完成',value:'SERVICE_FINISHED'},
            ],
           typeCodeList:[{lable:'保洁工单',value:'1'},{lable: '维修工单',value:'2'}],
            // 日期
            dateValue: [],        // 设备新增时间段
            beginDate: '',        // 设备新增开始时间
            endDate: '',          // 设备新增结束时间
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            update_success: "修改成功！",
            del_success: '删除成功！',
            acceptance_success: '验收成功！',
            hotelName:'',
            gdtype:'',
            index:'',               //默认酒店

         }
      },
     computed:{
       ...mapState(['hotelInfo']),
     },
      mounted() {
        this.status = this.$route.query.condition
         this.limit = sessionStorage.getItem('pageSize') * 1
        if (this.hotelId !== ''){
          this.getWorkOrders()
        }
      },
      methods: {
         // 获取工单列表
         getWorkOrders(){
            const url = hotelMan.workOrders
            const param = {
               status: this.status,
               beginDate: this.dateValue[0],
               endDate: this.dateValue[1],
               page: this.page,
               limit: this.limit,
               hotelId:this.hotelInfo.id,
               workType:this.gdtype
            }
            this.$axios.post(url, param).then(res => {
               // this.loading = false
               this.total = res.total
               this.tableData = res.records
              // this.tableData.map(item =>{
                // console.log(item)
              // })
            })
         },
         // 获取登录时间段
         getDatePacker(val){
            this.dateValue = val
         },
         // 搜索工单
         handleQuery(bool){
           if (bool){
             return this.getWorkOrders()
           }
            this.status = ''
            this.dateValue = []
         },
         // 改变每页数
         changePageNum(num){
            this.limit = num
            this.getWorkOrders()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getWorkOrders()
         },
         // 创建工单
         handleAdd(action){
            this.$router.push({path: '/work_order_add', query: {action}})
         },
         // 查看详情
         handleDetail(action, row){
           this.$router.push({path: '/work_order_detail', query: {action, id: row.id}})
         },
         // 删除工单
         handleDel(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let param = { id:id, isDelete: 'YES' }

               const url = hotelMan.delWorkOrder
               this.$axios.post(url, param).then(res => {
                  if (res.success){
                     this.$message({
                        showClose: true,
                        message: this.del_success,
                        type: 'success'
                     })
                     this.getWorkOrders()

                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id  && oldVal) {
                  this.getWorkOrders()
               }
            },
            deep: true
         }
      },
      filters:{
         stutas(data){
            if (data == 'NO_RECEIVED'){
               return '待接单'
            }
            if (data == 'ORDER_RECEIVED'){
               return '已接单'
            }
            if (data == 'SERVICE_FINISHED'){
               return '已完成'
            }
         },
      }
   }
</script>

<style lang="scss">
/deep/ .el-table th.is-leaf, .el-table td{text-align: center;}
/deep/ .el-table th.is-sortable{text-align: center;}
 .label{font-size: 14px;}

.hotelName{width: 260px;float: left;margin-left: 20px;}

  .pop-top{display: none;}
</style>
